<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h1>Open purchase orders</h1>
        <p>This report shows the open orders as of the end of the month selected.</p>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col class="col-4">
        <v-date-picker
          v-if="hidePicker === false"
          v-model="date"
          type="month"
        ></v-date-picker>
        <v-btn small @click="hidePicker = false" v-else>Show calendar</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table>
          <thead>
            <tr>
              <th>Order no</th>
              <th>Requisition date</th>
              <th>Amount</th>
              <th>Value received</th>
              <th>Received as of today</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tfoot>
            <tr>
              <td>Totals</td>
              <td>&nbsp;</td>
              <td>{{ totals.amount | currency }}</td>
              <td>{{ totals.received | currency }}</td>
              <td>{{ totals.receivedByToday | currency }}</td>
              <td>&nbsp;</td>
            </tr>
          </tfoot>
          <tbody>
            <tr>
              <td>Totals</td>
              <td>&nbsp;</td>
              <td>{{ totals.amount | currency }}</td>
              <td>{{ totals.received | currency }}</td>
              <td>{{ totals.receivedByToday | currency }}</td>
              <td>&nbsp;</td>
            </tr>
            <tr v-for="(po, index) in purchaseOrders" :key="`po_${index}`">
              <td>{{ po.id }}</td>
              <td>{{ po.date | tinyDate }}</td>
              <td>{{ po.amount | currency }}</td>
              <td>{{ po.valueReceived | currency }}</td>
              <td>{{ po.receivedByToday | currency }}</td>
              <td>
                <router-link :to="`/purchasing/purchase-orders-view/${po.id}`">
                  <v-btn x-small>View</v-btn>
                </router-link>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'WorkingTimeDirective',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  watch: {
    date() {
      this.getPurchaseOrders();
    },
  },
  data() {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      hidePicker: false,
      purchaseOrders: [],
      totals: {},
    };
  },
  methods: {
    getPurchaseOrders() {
      const postData = {};
      postData.date = this.date;
      axios.post(`/purchaseOrders/getReceivedByDate.json?token=${this.token}`, postData)
      .then((response) => {
        this.purchaseOrders = response.data.purchaseOrders;
        this.totals = response.data.totals;
        this.hidePicker = true;
      });
    },
  },
  mounted() {},
};
</script>
  